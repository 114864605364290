import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import kruziLogo from '../kruzi_logo.png';
import ministryLogo from '../ministry_logo.png';
import asmpLogo from '../asmp_logo.png';
import LockResetIcon from '@mui/icons-material/LockReset'; // For Change Password
import LogoutIcon from '@mui/icons-material/Logout'; // For Logout
import VisibilityIcon from '@mui/icons-material/Visibility'; // For View
import DownloadIcon from '@mui/icons-material/Download'; // For Download
import { Tabs, Tab } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';

function Home() {
  const [weatherStations, setWeatherStations] = useState([]); // Store multiple weather stations
  const [selectedStation, setSelectedStation] = useState(''); // Initialize to an empty string
  const [tabIndex, setTabIndex] = useState(0); // State to manage the selected tab
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const username = localStorage.getItem('username');
        const response = await axios.get(`http://3.92.252.184:8088/api/user/${username}`);
        setUserData(response.data);
        localStorage.setItem('name', response.data.name);
        const stations = response.data.weatherSt; // Store all weather stations
        setWeatherStations(stations); 
        const selectedStation = stations[0];      
        setSelectedStation(stations[0]);
        localStorage.setItem('weatherStation', stations[0]);
        
        // Retrieve the selected station from localStorage, if it exists
        // const storedStation = localStorage.getItem('weatherStation');
        
        // if (storedStation && stations.includes(storedStation)) {
        //   setSelectedStation(storedStation); // Set the selected station to the stored value
        // } else {
        //   setSelectedStation(stations[0]); // Set default to the first station if no stored station
        // }
      } catch (error) {
        console.error('Error fetching user data:', error);
        alert('Failed to fetch user data. Please try again later.');
      }
    };
  
    fetchUserData();
  }, []); // Run only once when the component mounts

  const name = localStorage.getItem('name');

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    const selectedStation = weatherStations[newValue];
    setSelectedStation(selectedStation);
    localStorage.setItem('weatherStation', selectedStation); // Store the selected station in local storage
  };

  const handleStopDateChange = (date) => {
    setStopDate(date);
  };

  const handleSubmit = async () => {
    if (startDate && stopDate) {
      const formattedStart = formatDate(startDate);
      const formattedStop = formatDate(stopDate);
      setLoading(true);
      setError(null);
      setIsSubmitted(true);

      try {
        const response = await axios.get(
          `http://3.92.252.184:8088/infapi/data/${selectedStation}/${formattedStart}/${formattedStop}` // Use selectedStation
        );
        setData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setData([]);
        } else {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please select both start and stop dates.');
    }
  };

  const handleChangePassword = () => {
    navigate(`/changepassword`);
  };
  const handleSettings = () => {
    navigate(`/settings`);
  };
  const handleContacts = () => {
    navigate(`/contacts`);
  };
  const handleDownloads = () => {
    navigate(`/Downloads`);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('name');
    localStorage.removeItem('weatherStation');
    navigate('/');
  };

  const downloadCSV = () => {
    if (data.length === 0) return;
  
    // Extract main headers and their subheaders, excluding 'Date'
    const mainHeaders = Object.keys(data[0]).filter((header) => header !== 'Date');
    
    // Prepare main header row with merged cells
    const mainHeaderRow = ['Date']; // 'Date' column should only appear once
    mainHeaders.forEach((mainHeader) => {
      const subHeaderCount = Object.keys(data[0][mainHeader]).length; // Number of subheaders under each main header
      mainHeaderRow.push(mainHeader, ...Array(subHeaderCount - 1).fill('')); // Merge cells by filling with empty strings
    });
  
    // Prepare subheader row, excluding 'Date'
    const subHeaderRow = ['']; // No subheader for 'Date'
    mainHeaders.forEach((mainHeader) => {
      const subHeaders = Object.keys(data[0][mainHeader]);
      subHeaderRow.push(...subHeaders);
    });
  
    // Combine the header rows into CSV format
    const csvRows = [
      mainHeaderRow.join(','), // Main headers row
      subHeaderRow.join(',')   // Subheaders row
    ];
  
    // Create data rows aligned with headers
    data.forEach((item) => {
      const row = [];
  
      // Add Date value first
      row.push(item['Date'] || '');
  
      // Add values for each main header's subheaders
      mainHeaders.forEach((mainHeader) => {
        const values = item[mainHeader] || {};
        Object.keys(data[0][mainHeader]).forEach((subHeader) => {
          const value = values[subHeader] !== undefined ? values[subHeader] : '-';
          row.push(typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value);
        });
      });
  
      csvRows.push(row.join(','));
    });
  
    // Generate CSV file and trigger download
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          paddingLeft: '75px',
          paddingRight: '75px',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          position: 'relative',
          left: 0,
          right: 0,
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
        }}
      >
        <img src={ministryLogo} alt="Left Logo" style={{ height: 65 }} />
        <img src={asmpLogo} alt="Center Logo" style={{ height: 65 }} />
        <img src={kruziLogo} alt="Right Logo" style={{ height: 65 }} />
      </Box>

      {/* Welcome and Buttons Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%', padding: '20px', paddingTop: '50px' }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Welcome {name}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
        <Button onClick={handleDownloads} variant="contained" size="large" startIcon={<DownloadIcon />}>
            Downloads
          </Button>
          <Button onClick={handleContacts} variant="contained" size="large" startIcon={<ContactsIcon />}>
            Contacts
          </Button>
          <Button onClick={handleSettings} variant="contained" size="large" startIcon={<SettingsIcon />}>
            Settings
          </Button>
          <Button onClick={handleChangePassword} variant="contained" size="large" startIcon={<LockResetIcon />}>
            Change Password
          </Button>
          <Button onClick={handleLogout} variant="contained" size="large" startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Box>
      </Box>

      {/* Main Content Section */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          padding: '20px',
          paddingBottom: '50px',
          border: '2px solid',
          borderColor: 'primary.main',
          borderRadius: '8px',
          mt: 2,
          boxSizing: 'border-box',
          width: '100%', // Ensure container is 100% width
          maxWidth: '1200px', // Optional: Set a maximum width for better responsiveness
          minWidth: '400px', // Adjust the minimum width here as needed
          margin: '0 auto',
          marginBottom: '10px', // Center container within the parent
        }}
      >
        <Typography variant="h4" gutterBottom>
          Agro Weather Station:
        </Typography>

        {/* Wrapper Box for Tabs with increased border */}
        <Box
          sx={{
            width: '100%',
            padding: '0', // No additional padding if you want the tabs to stretch
            marginBottom: '16px',  // Space between tabs and other content
          }}
        >
          {/* Tabs for Weather Stations */}
          <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            {weatherStations.map((station, index) => (
              <Tab key={station} label={station} />
            ))}
          </Tabs>
        </Box>

        <Box display="flex" gap={2} alignItems="center" sx={{ width: '100%' }}>
          <Typography variant="h6">Weather logs from</Typography>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select start date"
            style={{ width: '150px' }}
            popperPlacement="bottom-start"
          />
          <Typography variant="h6">to</Typography>
          <DatePicker
            selected={stopDate}
            onChange={handleStopDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select stop date"
            style={{ width: '150px' }}
            popperPlacement="bottom-start"
          />
          <Button onClick={handleSubmit} variant="contained" size="large" startIcon={<VisibilityIcon />} sx={{ ml: 2 }}>
            View
          </Button>
          {isSubmitted && data.length > 0 && (
            <Button
              onClick={downloadCSV}
              variant="contained"
              size="large"
              color="primary"
              startIcon={<DownloadIcon />}
              sx={{
                ml: 2,
                bgcolor: 'green', // Custom green color
                '&:hover': {
                  bgcolor: 'darkgreen', // Darker green on hover
                },
              }}
            >
              Download
            </Button>
          )}
        </Box>

        {/* Data Table and Messages Section */}
        {loading ? (
          <Typography variant="body1" mt={2}>
            Loading...
          </Typography>
        ) : error ? (
          <Typography variant="body1" mt={2} color="error">
            Error: {error}
          </Typography>
        ) : (
          isSubmitted && (
            <Box sx={{ mt: 2, width: '100%' }}>
              {data.length > 0 ? (
                <Box
                  sx={{
                    width: 'auto',
                    padding: '20px',
                    border: '0px solid',
                    borderColor: 'primary.main',
                    borderRadius: '8px',
                    mt: 2,
                    boxSizing: 'border-box',
                    overflowX: 'auto',
                  }}
                >
                  <Typography variant="body1" mb={1} textAlign="right" color="textSecondary">
                    Please scroll to see other parameters &gt;
                  </Typography>
                  <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                    <thead>
                      <tr>
                        {/* Main headers */}
                        <th
                          rowSpan={2}
                          style={{
                            border: '2px solid #ddd',
                            padding: '8px',
                            backgroundColor: '#f0f0f0',
                            textAlign: 'center',
                            width: '120px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Date
                        </th>
                        {Object.keys(data[0])
                          .filter((key) => key !== 'Date')
                          .map((header) => (
                            <th
                              key={header}
                              colSpan={Object.keys(data[0][header]).length}
                              style={{
                                border: '2px solid #ddd',
                                padding: '8px',
                                backgroundColor: '#f0f0f0',
                                textAlign: 'center',
                                width: `${140 * Object.keys(data[0][header]).length}px`,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {header}
                            </th>
                          ))}
                      </tr>
                      <tr>
                        {Object.keys(data[0])
                          .filter((key) => key !== 'Date')
                          .flatMap((header) =>
                            Object.keys(data[0][header]).map((subHeader) => (
                              <th
                                key={`${header}-${subHeader}`}
                                style={{
                                  border: '2px solid #ddd',
                                  padding: '8px',
                                  backgroundColor: '#f7f7f7',
                                  width: '140px',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  textAlign: 'center',
                                }}
                              >
                                {subHeader}
                              </th>
                            ))
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td
                            style={{
                              border: '2px solid #ddd',
                              padding: '8px',
                              textAlign: 'center',
                              width: '120px',
                            }}
                          >
                            {new Date(row['Date']).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </td>
                          {Object.keys(row)
                            .filter((key) => key !== 'Date')
                            .flatMap((header) => {
                              const values = row[header];
                              return Object.keys(values).map((subHeader) => (
                                <td
                                  key={`${rowIndex}-${header}-${subHeader}`}
                                  style={{
                                    border: '2px solid #ddd',
                                    padding: '8px',
                                    width: '140px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {values[subHeader] !== undefined ? values[subHeader] : '-'}
                                </td>
                              ));
                            })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <Typography variant="body1" mt={2}>
                  No data available for the selected date range.
                </Typography>
              )}
            </Box>
          )
        )}
      </Box>
      <Box
        sx={{
          mt: 'auto', // This pushes the footer to the bottom
          padding: '5px',
          textAlign: 'center',
          
        }}
      >
        <Typography variant="body2" color="textSecondary">
          copyright © {new Date().getFullYear()} Kruzi. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
}

export default Home;
