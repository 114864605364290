// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

// This component will protect routes that require authentication
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Check if the token is present

  // If token is not present, redirect to the login page
  if (!token) {
    return <Navigate to="/" replace />;
  }

  // If authenticated, render the child component
  return children;
};

export default ProtectedRoute;
