import React from 'react';
import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Button, Container, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import kruziLogo from '../kruzi_logo.png';
import LoginIcon from '@mui/icons-material/Login';
import background from '../background.jpg'; // Import the background image

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async (e) => {
        setError(null);
        setMessage('');

        e.preventDefault();
        try {
            const response = await axios.post('http://3.92.252.184:8088/login', { username, password });
            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('username', username);
                try {
                    await axios.post('http://3.92.252.184:8088/api/userLog', { username });
                } catch (logError) {
                    console.error('Failed to log user', logError);
                }
                navigate('/home');
            }
        } catch (error) {
            setError('Login failed. Username or Password incorrect');
        }
    };

    return (
        <Container maxWidth={false} disableGutters sx={{ padding: 0 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${background})`,  // Set background image
                    backgroundSize: 'cover',                // Ensure the background covers the entire screen
                    backgroundPosition: 'center',           // Center the background image
                    backgroundRepeat: 'no-repeat',          // Avoid repeating the background image
                    display: 'flex',                        // Use flexbox for centering
                    justifyContent: 'flex-end',             // Align the box towards the right side
                    alignItems: 'center',                   // Vertically center
                    paddingRight: '20vw',                   // Adjust this value to move the box towards the center-right position
                }}
            >
                <Box
                    sx={{
                        width: '400px',                     // Reduced width for a more compact login box
                        bgcolor: 'rgba(255, 255, 255, 0.8)',  // Semi-transparent background
                        color: 'black',
                        display: 'flex',
                        flexDirection: 'column',             // Stack content vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '20px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Add a slight shadow for emphasis
                    }}
                >
                    {/* Logo Image */}
                    <Box sx={{ marginBottom: '10px' }}>
                        <img
                            src={kruziLogo}  // Replace with your logo image path
                            alt="Logo"
                            style={{ width: '180px', height: 'auto' }} // Adjust size for a smaller logo
                        />
                    </Box>

                    <form onSubmit={handleLogin}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                            <Typography variant="h4">Login</Typography> {/* Reduced text size for better fit */}
                        </Box>
                        
                        <Box sx={{ width: '100%', marginBottom: '16px' }}>
                            <Typography variant="body1" fontWeight="bold">
                                Username
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Enter Username"
                                name="username"
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </Box>
                        
                        <Box sx={{ width: '100%', marginBottom: '24px' }}>
                            <Typography variant="body1" fontWeight="bold">
                                Password
                            </Typography>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                                placeholder="Enter Password"
                                name="password"
                                onChange={(event) => setPassword(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        {/* Message Container with Fixed Height */}
                        <Box sx={{ height: '30px', marginBottom: '16px', textAlign: 'center' }}>
                            {message && <Typography variant="body1" color="success.main">{message}</Typography>}
                            {error && <Typography variant="body1" color="error">{error}</Typography>}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" type="submit" size="large" startIcon={<LoginIcon />}>
                                Login
                            </Button>
                        </Box>
                    </form>

                    <Typography variant="body2" sx={{ marginTop: '20px', textAlign: 'center' }}>
                        Contact{' '}
                        <span style={{ color: 'blue' }}>support@kruzi.co.uk</span> for technical support and password reset.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default Login;
