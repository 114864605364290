import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, List, ListItem } from '@mui/material';
import kruziLogo from '../kruzi_logo.png';
import ministryLogo from '../ministry_logo.png';
import asmpLogo from '../asmp_logo.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Settings = () => {
  const [zones, setZones] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchZones = async () => {
      const storedWeatherStation = localStorage.getItem('weatherStation'); 
      console.log('Stored Weather Station:', storedWeatherStation);
      if (!storedWeatherStation) {
        setError('No weather station selected.');
        return;
      }

      try {
        const response = await axios.get(`http://3.92.252.184:8088/api/zone/${storedWeatherStation}`);
        setZones(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('No zones found for the selected weather station.');
        } else {
          setError('An error occurred while fetching the zones.');
        }
      }
    };
   
    fetchZones();
  }, []);

  const handleBack = () => {
    navigate(`/home`);
  };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          paddingLeft: '75px',
          paddingRight: '75px',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          position: 'relative',
          left: 0,
          right: 0,
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
        }}
      >
        <img src={ministryLogo} alt="Left Logo" style={{ height: 65 }} />
        <img src={asmpLogo} alt="Center Logo" style={{ height: 65 }} />
        <img src={kruziLogo} alt="Right Logo" style={{ height: 65 }} />
      </Box>

      {/* Main Content Section */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
     
          minHeight: '70vh',
          padding: 0,
          paddingTop: '30px',
        }}
      >
        <Box
          sx={{
            width: 600,
            minHeight: 400,
            bgcolor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: '8px',
            padding: '30px',
            paddingBottom: '20px',
            boxSizing: 'border-box',
          }}
        >
          {/* Main Header */}
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            SETTINGS
          </Typography>

          {/* Error Message */}
          {error && (
            <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}

          {/* Zones List */}
          {zones.length > 0 ? (
            <List sx={{ width: '100%' }}>
              {zones.map((zone, index) => (
                <ListItem key={index} sx={{ paddingBottom: 2 }}>
                  <Box sx={{ width: '100%' }}>
                    {/* Sub-header combining weStId and cropId */}
                    <Typography variant="h5" sx={{ paddingBottom: 2 }}>{`${zone.weStId} ${zone.cropType}`}</Typography>
                    
                    {/* Zone Details */}
                    <Box sx={{ paddingLeft: 2 }}>
                      <Box sx={{ display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Plot size</Typography>
                        <Typography variant="body1">: {zone.plot.plotArea} m²</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Crop</Typography>
                        <Typography variant="body1">: {zone.cropType}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Planted date</Typography>
                        <Typography variant="body1">
                          : {new Date(zone.crop.dayZero).toLocaleDateString('en-GB')}
                        </Typography>
                      </Box>

                      {/* Conditional Rendering Based on Irrigation Type */}
                      <Box sx={{ display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Irrigation system</Typography>
                        <Typography variant="body1">: {zone.irri.type === 'drip' ? 'Drip tape' : 'Sprinkler'}</Typography>
                      </Box>
                      {zone.irri.type === 'drip' ? (
                        <>
                          <Box sx={{ display: 'flex', marginBottom: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Total length</Typography>
                            <Typography variant="body1">: {zone.irri.emtLen} m</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', marginBottom: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Emitter gap</Typography>
                            <Typography variant="body1">: {zone.irri.emtGap} m</Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box sx={{ display: 'flex', marginBottom: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>No. of Sprinklers</Typography>
                            <Typography variant="body1">: {zone.irri.emtQty}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', marginBottom: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Discharge</Typography>
                            <Typography variant="body1">: {zone.irri.discharge} L/hr</Typography>
                          </Box>
                        </>
                      )}
                      <Box sx={{ display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Soil Type</Typography>
                        <Typography variant="body1">: {zone.soil}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No zones available to display.
            </Typography>
          )}

          <Button
            variant="contained"
            size="large"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          {/* Notification Message */}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: 'center', marginTop: 2, whiteSpace: 'nowrap' }}
          >
            Please notify any changes immediately to {' '}
            <Box component="span" sx={{ color: '#1976d2' }}>
              support@kruzi.co.uk
            </Box>{' '} or phone: 0712258128
          </Typography>
          
        </Box>
      </Container>


      {/* Footer Section */}
      <Box
        sx={{
          mt: 'auto',
          padding: '5px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          copyright © {new Date().getFullYear()} Kruzi. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Settings;
