import React, { createContext, useState, useContext } from 'react';

const WeatherStationContext = createContext();

export const WeatherStationProvider = ({ children }) => {
    const [weatherStation, setWeatherStation] = useState(() => {
      // Try to get the weather station from localStorage or set a default value
      const storedStation = localStorage.getItem('weatherStation');
      return storedStation || ''; // If nothing is stored, default to empty string
    });
  
    return (
      <WeatherStationContext.Provider value={{ weatherStation, setWeatherStation }}>
        {children}
      </WeatherStationContext.Provider>
    );
  };