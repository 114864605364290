import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Button } from '@mui/material';
import kruziLogo from '../kruzi_logo.png';
import ministryLogo from '../ministry_logo.png';
import asmpLogo from '../asmp_logo.png';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from '@mui/material';



const Downloads = () => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/home`);
      };
  

  return (
    <Container
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          paddingLeft: '75px',
          paddingRight: '75px',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          position: 'relative',
          left: 0,
          right: 0,
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
        }}
      >
        <img src={ministryLogo} alt="Left Logo" style={{ height: 65 }} />
        <img src={asmpLogo} alt="Center Logo" style={{ height: 65 }} />
        <img src={kruziLogo} alt="Right Logo" style={{ height: 65 }} />
      </Box>

      {/* Main Content Section */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',        
          minHeight: '30vh',
          padding: 0,
          paddingTop: '30px',
        }}
      >
        <Box
          sx={{
            width: 600,
            minHeight: 400,
            bgcolor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: '8px',
            padding: '30px',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            DOWNLOADS
          </Typography>
          <Box sx={{display: 'flex',justifyContent: 'flex-start',alignItems: 'center',}}>
          <Link
            href="/UserGuide2_0.pdf"
            download="userguide.pdf"
            sx={{
                color: 'green', // Custom green color for the link
                fontSize: '1.2rem',
                textDecoration: 'none', // Remove underline
                '&:hover': {
                color: 'darkgreen', // Darker green on hover
                textDecoration: 'underline', // Optional underline on hover
                },
            }}
            >
            User Guide
            </Link>
          </Box>
          <Button
            variant="contained"
            size="large"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            sx={{
                mt: 'auto', // Pushes the button to the bottom
                alignSelf: 'center', // Center the button horizontally if needed
              }}
          >
            Back
          </Button>
        </Box>
      </Container>


      {/* Footer Section */}
      <Box
        sx={{
          mt: 'auto',
          padding: '5px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          copyright © {new Date().getFullYear()} Kruzi. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Downloads;
