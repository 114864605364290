import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, List, ListItem } from '@mui/material';
import kruziLogo from '../kruzi_logo.png';
import ministryLogo from '../ministry_logo.png';
import asmpLogo from '../asmp_logo.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Contacts = () => {
  const [PUCs, setPUCs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPUCs = async () => {
      const storedWeatherStation = localStorage.getItem('weatherStation'); 
      console.log('Stored Weather Station:', storedWeatherStation);
      if (!storedWeatherStation) {
        setError('No weather station selected.');
        return;
      }

      try {
        const response = await axios.get(`http://3.92.252.184:8088/api/puc/${storedWeatherStation}`);
        setPUCs(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('No PUCs found for the selected weather station.');
        } else {
          setError('An error occurred while fetching the zones.');
        }
      }
    };
   
    fetchPUCs();
  }, []);
  
  const handleBack = () => {
    navigate(`/home`);
  };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          paddingLeft: '75px',
          paddingRight: '75px',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          position: 'relative',
          left: 0,
          right: 0,
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
        }}
      >
        <img src={ministryLogo} alt="Left Logo" style={{ height: 65 }} />
        <img src={asmpLogo} alt="Center Logo" style={{ height: 65 }} />
        <img src={kruziLogo} alt="Right Logo" style={{ height: 65 }} />
      </Box>

      {/* Main Content Section */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',

          minHeight: '100vh',
          padding: 0,
          paddingTop: '30px',
        }}
      >
        <Box
          sx={{
            width: 600,
            minHeight: 400,
            bgcolor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: '8px',
            padding: '30px',
            boxSizing: 'border-box',
            paddingBottom: '20px',
          }}
        >
          {/* Main Header */}
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            CONTACT DETAILS
          </Typography>

          {/* Error Message */}
          {error && (
            <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}

        {/* PUCs List */}
        {PUCs.length > 0 ? (
        <List sx={{ width: '100%' }}>
            {PUCs.map((PUCs, index) => (
            <ListItem key={index} sx={{ paddingBottom: 2 }}>
                <Box sx={{ width: '100%' }}>
                <Box sx={{ paddingLeft: 2 }}>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>PUC Name</Typography>
                    <Typography variant="body1">: {PUCs.PUCname}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>No. of Farmers</Typography>
                    <Typography variant="body1">: {PUCs.farmersCount}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>PUC Address</Typography>
                    <Typography variant="body1">: {PUCs.PUCaddress}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Technical Person</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Name</Typography>
                    <Typography variant="body1">: {PUCs.technical.name}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Email</Typography>
                    <Typography variant="body1">: {PUCs.technical.email}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 1 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Phone</Typography>
                    <Typography variant="body1">: {PUCs.technical.phone}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Chairman</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Name</Typography>
                    <Typography variant="body1">: {PUCs.chairman.name}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Email</Typography>
                    <Typography variant="body1">: {PUCs.chairman.email}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 1 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Phone</Typography>
                    <Typography variant="body1">: {PUCs.chairman.phone}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Fruit Desk Officer</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Name</Typography>
                    <Typography variant="body1">: {PUCs.fruitDeskOfficer.name}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Email</Typography>
                    <Typography variant="body1">: {PUCs.fruitDeskOfficer.email}</Typography>
                    </Box>
                    <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 1 }}> {/* Indented Box */}
                    <Typography variant="body1" sx={{  width: '120px' }}>Phone</Typography>
                    <Typography variant="body1">: {PUCs.fruitDeskOfficer.phone}</Typography>
                    </Box>

                    {/* Officers List */}
                    {/* <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>Officers</Typography>
                    </Box> */}
                    {PUCs.officers && PUCs.officers.map((officer, officerIndex) => (
                    <Box key={officerIndex} sx={{  marginBottom: 1 }}>
                        <Box sx={{ display: 'flex', marginBottom: 0.5 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '150px' }}>
                            Officer {officerIndex + 1}
                        </Typography>
                        </Box>
                        <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}>
                        <Typography variant="body1" sx={{ width: '120px' }}>Name</Typography>
                        <Typography variant="body1">: {officer.name}</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}>
                        <Typography variant="body1" sx={{ width: '120px' }}>Email</Typography>
                        <Typography variant="body1">: {officer.email}</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 0.5 }}>
                        <Typography variant="body1" sx={{ width: '120px' }}>Phone</Typography>
                        <Typography variant="body1">: {officer.phone}</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: 4, display: 'flex', marginBottom: 1 }}>
                        <Typography variant="body1" sx={{ width: '120px' }}>Designation</Typography>
                        <Typography variant="body1">: {officer.designation}</Typography>
                        </Box>
                    </Box>
                    ))}

                </Box>
                </Box>
            </ListItem>
            ))}
        </List>
        ) : (
        <Typography variant="body1" color="textSecondary">
            No PUCs available to display.
        </Typography>
        )}


          <Button
            variant="contained"
            size="large"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>


          {/* Notification Message */}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: 'center', marginTop: 2, whiteSpace: 'nowrap' }}
          >
            Please notify any changes immediately to {' '}
            <Box component="span" sx={{ color: '#1976d2' }}>
              support@kruzi.co.uk
            </Box>{' '} or phone: 0712258128
          </Typography>
        </Box>
      </Container>


      {/* Footer Section */}
      <Box
        sx={{
          mt: 'auto',
          padding: '5px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          copyright © {new Date().getFullYear()} Kruzi. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Contacts;
