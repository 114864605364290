import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import kruziLogo from '../kruzi_logo.png';
import ministryLogo from '../ministry_logo.png';
import asmpLogo from '../asmp_logo.png';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChangePassword = () => {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handlePasswordChange = async () => {
    setError(null);
    setMessage('');

    // Validation for password match
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }

    try {
      // Retrieve the user data to validate the old password
      const response = await axios.get(`http://3.92.252.184:8088/api/user/${username}`);
      
      // Check if the old password matches
      if (response.data.password !== oldPassword) {
        setError('Old password is incorrect.');
        return;
      }

      // Proceed to update the password
      await axios.put(`http://3.92.252.184:8088/api/user/${username}`, {
        username: username,
        password: newPassword,
      });

      alert('Password updated successfully!');
      navigate(`/home`);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError(`No record found for username: ${username}`);
      } else {
        setError('An error occurred while updating the password.');
      }
    }
  };

  const handleBack = () => {
    navigate(`/home`);
  };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          paddingLeft: '75px',
          paddingRight: '75px',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          position: 'relative',
          left: 0,
          right: 0,
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
        }}
      >
        <img src={ministryLogo} alt="Left Logo" style={{ height: 65 }} />
        <img src={asmpLogo} alt="Center Logo" style={{ height: 65 }} />           
        <img src={kruziLogo} alt="Right Logo" style={{ height: 65 }} />
       
      </Box>


      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '50vh',
          padding: 0,
          paddingTop: '30px', // Reduced top padding
          paddingBottom: '10px', // Added bottom padding to balance space
        }}
      >
        <Box
          sx={{
            width: 600,
            height: 500, // Adjust height to fit content
            bgcolor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            
            alignItems: 'center',
            border: '1px solid black',
            borderRadius: '8px',
            padding: '5px', // Padding around content
            boxSizing: 'border-box',
            position: 'relative',
            marginTop: '0px', // Reduced top margin to bring content closer to the top border
            marginBottom: '10px', // Reduced bottom margin to bring button closer to the bottom border
            paddingTop: '30px',
            paddingBottom: '20px',
            
          }}
        >
          <Typography variant="h3" gutterBottom sx={{marginBottom:'50px'}}>
            Change Password
          </Typography>
      
          <Box sx={{ width: '60%', mb: 2 }}>
            <TextField
              fullWidth
              type="password"
              label="Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="password"
              label="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="password"
              label="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
          </Box>
      
          {/* Space for error messages */}
          <Box sx={{ height: '30px', marginBottom: '16px', textAlign: 'center' }}>
            {message && <Typography variant="body1" sx={{ color: 'success.main' }}>{message}</Typography>}
            {error && <Typography variant="body1" color="error">{error}</Typography>}
          </Box>
      
          <Button
            variant="contained"
            size="large"
            onClick={handlePasswordChange}
            startIcon={<LockRoundedIcon />}
          >
            Change
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            sx={{marginTop:'10px'}}
          >
            Back
          </Button>
          

        </Box>

        
      </Container>

      
      <Box
        sx={{
          mt: 'auto', // This pushes the footer to the bottom
          padding: '5px',
          textAlign: 'center',
          
        }}
      >
        <Typography variant="body2" color="textSecondary">
          copyright © {new Date().getFullYear()} Kruzi. All rights reserved.
        </Typography>
      </Box>

      
      
    </Container>

  );
};

export default ChangePassword;
