import { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Login from './components/Login'
import Home from './components/Home'
import ProtectedRoute from './components/ProtectedRoute'
import ChangePassword from './components/ChangePassword'
import Settings from './components/Settings'
import Contacts from './components/Contacts'
import Downloads from './components/Downloads'

function App() {

  return (
    <BrowserRouter>
     <Routes>
      <Route path='/' element={<Login/>}></Route>
      <Route path='/home' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
      <Route path="/changepassword" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>}></Route>
      <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>}></Route>
      <Route path="/contacts" element={<ProtectedRoute><Contacts /></ProtectedRoute>}></Route>
      <Route path="/downloads" element={<ProtectedRoute><Downloads /></ProtectedRoute>}></Route>
      </Routes>
    </BrowserRouter>

  )
}

export default App
